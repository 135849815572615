import React, { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import { Row, Col, Container, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

const Relawan = () => {
  const gContext = useContext(GlobalContext);
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      > 
      <Helmet>
        <title>Pendaftaran Relawan - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Organisasi kami menyambut baik para individu yang tertarik untuk berkomitmen menjadi relawan kami. Kami membukan banyak kesempatan peluang menjadi relawan dengan kisaran waktu yang variatif, dari jangka pendek ke tugas jangka panjang" />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/relawan/" />
        <meta property="og:title" content="Pendaftaran Relawan" />
        <meta property="og:description" content="Organisasi kami menyambut baik para individu yang tertarik untuk berkomitmen menjadi relawan kami. Kami membukan banyak kesempatan peluang menjadi relawan dengan kisaran waktu yang variatif, dari jangka pendek ke tugas jangka panjang" />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Pendaftaran Relawan"  />
        <meta name="twitter:description" content="Organisasi kami menyambut baik para individu yang tertarik untuk berkomitmen menjadi relawan kami. Kami membukan banyak kesempatan peluang menjadi relawan dengan kisaran waktu yang variatif, dari jangka pendek ke tugas jangka panjang" />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
                <div className="px-md-15 text-center mb-13 mb-lg-22">
                  <h2 className="title gr-text-2 mb-8 mb-lg-12">
                    Pendaftaran Relawan
                  </h2>
                  <p className="gr-text-8 mb-0">
                    Organisasi kami menyambut baik para individu yang tertarik untuk berkomitmen menjadi relawan kami. 
                    Kami membuka kesempatan untuk menjadi relawan dengan kisaran waktu yang variatif, dari 
                    jangka pendek ke tugas jangka panjang. Para relawan ditawarkan dalam berbagai bidang termasuk, 
                    namun tidak terbatas pada, kampanye, penggalangan dana, pemasaran dan komunikasi, kebijakan publik, 
                    serta penelitian dan pekerjaan administratif.
                  </p>
                </div>
            </Row>
          </Container>
        </div>
        <div className="bg-default-2 pt-15 pt-lg-23 pb-13 pb-lg-27">
          <Container>
            <div className="section-title text-center mb-13 mb-lg-20">
              <h2 className="gr-text-3 mb-0">Form Pendaftaran Relawan</h2>
            </div>
            <Row>
              <Col xs="12">
                <div className="px-md-13 px-xl-15">
                  <form accept-charset="UTF-8" action="https://www.formbackend.com/f/9da65a7b2b837131" method="POST" className="cta-form bg-white light-mode rounded-10 px-8 py-8">
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="name">Nama Lengkap </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="name"
                    name="name"
                    placeholder="i.e. Budi"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="email">Email </label>
                  <input
                    type="email"
                    className="form-control gr-text-11"
                    id="email"
                    name="email"
                    placeholder="i.e. budi@mail.com"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="phone">Nomor HP </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="phone"
                    name="phone"
                    placeholder="i.e. +6281234567890"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="lahir">Tanggal Lahir </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="lahir"
                    name="lahir"
                    placeholder="i.e. 1 Januari 1990"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="alamat">Alamat Tinggal Saat Ini </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="alamat"
                    name="alamat"
                    placeholder="i.e. Jl. Margonda Raya No. 123, Pondok Cina, Beji"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="kota">Kota </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="kota"
                    name="kota"
                    placeholder="i.e. Depok"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="provinsi">Provinsi </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="provinsi"
                    name="provinsi"
                    placeholder="i.e. Jawa Barat"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="telegram">Akun Telegram </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="telegram"
                    name="telegram"
                    placeholder="i.e. @durov"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="twitter">Akun Twitter </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="twitter"
                    name="twitter"
                    placeholder="i.e. @jack"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="profesi">Profesi saat ini </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="profesi"
                    name="profesi"
                    placeholder="i.e. Mahasiswa, Jurnalis, Full Stack Developer, Peneliti dsb"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="kontakdarurat">Informasi Kontak Darurat </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="kontakdarurat"
                    name="kontakdarurat"
                    placeholder="i.e. +6281234567890"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="namakontakdarurat">Nama Kontak Kontak Darurat </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="namakontakdarurat"
                    name="namakontakdarurat"
                    placeholder="i.e. Sumiyati"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="hubungan">Hubungan dengan Personal Kontak</label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="hubungan"
                    name="hubungan"
                    placeholder="i.e. Ibu Kandung"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="motivasi">Motivasi Bergabung Menjadi Relawan</label>
                  <textarea
                    id="motivasi"
                    name="motivasi"
                    className="form-control gr-text-11 border-gray-3 gr-textarea-height"
                    placeholder="Jelaskan secara singkat alasan kamu ingin bergabung menjadi relawan Ethical Hacker Indonesia"
                    required
                    ></textarea>
                </div>
                <Button type="submit" className="gr-hover-y rounded-8 w-100">
                  Kirim
                </Button>
                </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Relawan;
